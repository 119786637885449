.see.see {
  outline: solid 2px #00a8a8;
  border-radius: 10px;
  background-color: #00a8a8;
}

.buttonText {
  color: #fff;
  font-size: 20px;
  line-height: 100%;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

.placeholderPicture.placeholderPicture {
  width: initial;
  height: 220px;
  align-self: stretch;
  border-radius: 10px;
  background-color: #161e2133, #dde1e6;
  background-image:
    /* webpackIgnore: true */
    url('../../assets/placeholderPicture2.jpg');
}

.placeholderPicture2.placeholderPicture2 {
  width: initial;
  height: 220px;
  align-self: stretch;
  border-radius: 10px;
  background-color: #161e2133, #dde1e6;
  background-image:
    /* webpackIgnore: true */
    url('../../assets/placeholderPicture3.jpg');
}

.placeholderPicture3.placeholderPicture3 {
  width: initial;
  height: 220px;
  align-self: stretch;
  border-radius: 10px;
  background-color: #161e2133, #dde1e6;
  background-image:
    /* webpackIgnore: true */
    url('../../assets/placeholderPicture4.jpg');
}

.placeholderPicture4.placeholderPicture4 {
  width: 50%;
  height: 500px;
  background-color: initial;
  background-image:
    /* webpackIgnore: true */
    url('../../assets/placeholderPicture5.jpg');
}

.sectionText.sectionText {
  align-items: flex-start;
}

.top.top {
  align-items: center;
}

.secondaryHeadline {
  color: #21272a;
  font-size: 36px;
  line-height: 110%;
  font-weight: bold;
  font-family: Genos, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.sectionText2.sectionText2 {
  width: initial;
  align-self: stretch;
  align-items: flex-start;
}

.top2.top2 {
  align-items: center;
}

.secondaryHeadline2 {
  color: #000;
  font-size: 36px;
  line-height: 110%;
  font-weight: bold;
  font-family: Genos, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.menu.menu {
  place-content: initial;
}

.menuItem {
  color: #161e21;
  font-size: 16px;
  line-height: 100%;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 0.005em;
  text-transform: uppercase;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

.menuItem2 {
  color: #161e21;
  font-size: 16px;
  line-height: 100%;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 0.005em;
  text-transform: uppercase;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

.menuItem3 {
  color: #161e21;
  font-size: 16px;
  line-height: 100%;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 0.005em;
  text-transform: uppercase;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

.menuItem4 {
  color: #161e21;
  font-size: 16px;
  line-height: 100%;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 0.005em;
  text-transform: uppercase;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

.button.button {
  width: 124px;
  gap: initial;
  padding: initial;
}

.iconJamIconsOutlineLogosShoppi.iconJamIconsOutlineLogosShoppi {
  position: initial;
}

.vector.vector {
  position: initial;
  top: initial;
  right: initial;
  bottom: initial;
  left: initial;
}

.vector {
  position: initial;
  top: initial;
  right: initial;
  bottom: initial;
  left: initial;
}

.icon {
  width: 100%;
  height: 100%;
}

.textContainer.textContainer {
  width: 68px;
}

.buttonText2 {
  color: #161e21;
  font-size: 16px;
  line-height: 100%;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  width: 68px;
  flex-direction: column;
}

.badge.badge.badge {
  width: 17px;
  padding: initial;
  background-color: #00a8a8;
}

.text {
  color: #fff;
  font-size: 12px;
  line-height: 140%;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: 6px;
  flex-direction: column;
}

.button2.button2 {
  width: 108px;
  padding: initial;
  outline: solid NaNpx #00a8a8;
  outline-offset: initial;
  border-radius: 10px;
  background-color: #00a8a8;
}

.textContainer2.textContainer2 {
  width: 84px;
  height: 18px;
  padding: initial;
}

.buttonText3 {
  color: #fff;
  font-size: 18px;
  line-height: 100%;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  width: 52px;
  height: 18px;
  flex-direction: column;
}

.root {
  width: 100%;
  height: 100%;
  max-height: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  overflow: hidden;
}

.hero {
  position: relative;
  height: 787.7594px;
  align-self: stretch;
}

.bG {
  width: 80%;
  background-color: #161d20;
}

.frame33548 {
  position: absolute;
  left: 5.0364%;
  right: 58.0704%;
  top: 21.0724%;
  bottom: 30.6895%;
  width: 563.7279px;
  height: min-content;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 70px;
}

.unnamed {
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}

.textBlock {
  color: #fff;
  font-size: 64px;
  line-height: 100%;
  font-weight: bold;
  font-family: Genos, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  word-wrap: break-word;
  max-width: 100%;
}

._20 {
  max-width: 100%;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: normal;
  flex-direction: column;
  padding-right: 20px;
  padding-left: 20px;
}

.textBlock3 {
  width: 100%;
  height: min-content;
  color: #fff;
  font-size: 22px;
  line-height: 125%;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 0.005em;
  text-transform: uppercase;
}

.button3 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  border-radius: 20px;
}

.group {
  position: absolute;
  left: 37.1231%;
  right: 16.2768%;
  top: 0%;
  bottom: 7.8511%;
  width: 712.0491px;
  height: 725.9115px;
  overflow: visible;
}

.icon2 {
  width: 100%;
  height: 100%;
}

.image811 {
  position: absolute;
  left: 25.9709%;
  right: 0%;
  top: 26.8812%;
  bottom: 0;
  width: 1131px;
  height: 576px;
  background-image:
    /* webpackIgnore: true */
    url('../../assets/image811.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.whatWeDo {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 80px;
  padding: 20px 80px;
  background-color: #fff;
}

.row {
  height: min-content;
  flex: 1;
  gap: 20px;
}

.content {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.titleCategory {
  width: min-content;
  height: min-content;
  flex-direction: column;
}

.title {
  color: #161e21;
  font-size: 20px;
  line-height: 110%;
  font-weight: bold;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}

.content2 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.titleCategory2 {
  width: min-content;
  height: min-content;
  flex-direction: column;
}

.title2 {
  color: #161e21;
  font-size: 20px;
  line-height: 110%;
  font-weight: bold;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

.content3 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.titleCategory3 {
  width: min-content;
  height: min-content;
  flex-direction: column;
}

.title3 {
  color: #161e21;
  font-size: 20px;
  line-height: 110%;
  font-weight: bold;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  /* text-align: end; */
  align-items: end;
  white-space: nowrap;
  flex-direction: column;
}

.sectionText3 {
  width: 80%;
  max-width: 384px;
  height: min-content;
  flex-direction: column;
  place-content: center;
  gap: 16px;
}

.top3 {
  width: 100%;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.secondaryHeadline3 {
  color: #161e21;
  font-size: 55px;
  line-height: 110%;
  font-weight: bold;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  /* width: 419px; */
  height: min-content;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.paragraph {
  color: #161e21;
  font-size: 20px;
  line-height: 140%;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.frame10 {
  width: 100%;
  flex: 1;
  align-self: stretch;
  place-content: space-between;
  align-items: center;
  padding: 0 10%;
  overflow: hidden;
}

.frame11 {
  width: 50%;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
}

.unnamed2 {
  height: min-content;
  align-self: stretch;
  align-items: end;
  flex-direction: column;
}

.textBlock5 {
  color: #000;
  font-size: 32px;
  line-height: 100%;
  font-weight: bold;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 0.29em;
}

.textBlock6 {
  color: #000;
  font-size: 32px;
  line-height: 100%;
  font-weight: bold;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 0.29em;
}

.unnamed3 {
  height: min-content;
  align-self: stretch;
  align-items: end;
  flex-direction: column;
}

.textBlock7 {
  color: #000;
  font-size: 24px;
  line-height: 140%;
  font-weight: 500;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}

.textBlock8 {
  color: #000;
  font-size: 24px;
  line-height: 140%;
  font-weight: 500;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}

.textBlock9 {
  color: #000;
  font-size: 24px;
  line-height: 140%;
  font-weight: 500;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}

.costumerRecommendation {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  align-items: center;
  padding: 20px 80px;
  background-color: #fff;
}

.sectionText4 {
  width: 384px;
  height: min-content;
  flex-direction: column;
}

.top4 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.secondaryHeadline4 {
  color: #161e21;
  font-size: 36px;
  line-height: 110%;
  font-weight: bold;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  width: 419px;
  height: min-content;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.row2 {
  height: min-content;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
}

.ctaMiddle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: min-content;
  align-self: stretch;
  gap: 15px;
  padding: 80px 0px;
  background-color: #161e21;
  box-shadow: inset 0px -1px 0px #dde1e6;
}

.see2 {
  width: min-content;
  height: 56px;
  place-content: center;
  align-items: center;
  padding: 16px;
  outline: solid 2px #00a8a8;
  outline-offset: -2px;
  border-radius: 10px;
  background-color: #00a8a8;
}

.textContainer3 {
  z-index: 0;
  width: min-content;
  height: min-content;
  padding: 0 16px;
}

.buttonText4 {
  color: #fff;
  font-size: 20px;
  line-height: 100%;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

.unnamed4 {
  color: #fff;
  font-size: 42px;
  line-height: 125%;
  font-family: Geo, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: normal;
  width: 50%;
  flex-direction: column;
}

.gallery {
  height: min-content;
  width: 100%;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  padding: 50px 10px;
  background-color: #fff;
}

.frame1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

/* 
.contentBox {
  min-width: 100px;
  max-width: 268px;
  flex-direction: column;
  outline: solid 1px #dde1e6;
  outline-offset: -1px;
  background-color: #fff;
}

.image {
  width: 100%;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
} */
.contentBox {
  width: 100%;
  max-width: 268px;
  height: 200px; /* Set a fixed height for uniformity, adjust as needed */
  overflow: hidden; /* Ensure images don't overflow their containers */
  outline: solid 1px #dde1e6;
  outline-offset: -1px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}

.companiesLogos {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 80px;
  box-shadow: inset 0px -1px 0px #dde1e6;
}

.row3 {
  height: 150px;
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.icon3 {
  width: 100%;
  height: 100%;
}

.circleIcon {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}

.circleIcon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cta {
  height: 600px;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  align-items: center;
  padding: 10px;
  background-color: #161e21;
  overflow: hidden;
}

.frame7 {
  position: relative;
  width: min-content;
  height: min-content;
  flex-direction: column;
  place-content: center;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 60px 100px;
  outline: solid 10px #d9d9d9;
  outline-offset: -10px;
  border-radius: 10px;
}

.frame15 {
  z-index: 0;
  width: min-content;
  height: min-content;
  overflow: hidden;
}

.unnamed5 {
  color: #fff;
  font-size: 36px;
  line-height: 110%;
  font-family: Galdeano, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  width: 80vw;
  max-width: 340px;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.frame12 {
  z-index: 0;
  width: 300px;
  height: 50px;
  place-content: flex-end;
  align-items: center;
  padding: 5px 20px;
  background-color: #fff;
  overflow: hidden;
}

.unnamed6 {
  color: #4f4d4d99;
  font-size: 32px;
  line-height: 100%;
  font-family: Galdeano, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}

.frame13 {
  z-index: 0;
  width: 300px;
  height: 50px;
  place-content: flex-end;
  align-items: center;
  padding: 5px 20px;
  background-color: #fff;
  overflow: hidden;
}

.unnamed7 {
  color: #4f4d4d99;
  font-size: 32px;
  line-height: 100%;
  font-family: Galdeano, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}

.frame14 {
  z-index: 0;
  width: 300px;
  height: 50px;
  place-content: center;
  align-items: center;
  padding: 0 52px;
  background-color: #00a8a8;
  overflow: hidden;
}

.unnamed8 {
  color: #fff;
  font-size: 20px;
  line-height: 110%;
  font-weight: bold;
  font-family: Genos, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}

@media screen and (max-width: 1200px) {
  .image811 {
    width: 724.78px;
    height: 376px;
    top: 50%;
  }

  .bG {
    width: 100%;
  }

  .whatWeDo {
    height: min-content;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    gap: 80px;
    padding: 20px 80px;
    background-color: #fff;
  }

  .row {
    height: min-content;
    width: 100%;
  }

  .unnamed4 {
    font-size: 34px;
  }

  .contentBox {
    max-width: 200px;
    /* Set maximum width instead of fixed width */
  }
}

@media screen and (max-width: 992px) {
  .contentBox {
    max-width: 150px;
    /* Set maximum width instead of fixed width */
  }
}

@media screen and (max-width: 768px) {
  .hero {
    width: 100%;
    position: relative;
  }

  .frame33548 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: min-content;
    flex-direction: column;
    place-content: center;
    align-items: center;
    gap: 70px;
    z-index: 10;
  }

  .whatWeDo {
    padding: 20px 0px;
  }

  .row {
    display: flex;
    flex-direction: column;
    padding-left: 80px;
    padding-right: 80px;
  }

  .unnamed4 {
    font-size: 24px;
  }

  .circleIcon {
    width: 120px;
    height: 120px;
  }
}

@media screen and (max-width: 576px) {
  .textBlock {
    font-size: 38px;
  }

  .placeholderPicture4.placeholderPicture4 {
    width: 100%;
  }

  .frame11 {
    width: 100%;
  }

  .frame10 {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 0 10%;
    gap: 20px;
  }

  .companiesLogos {
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    padding: 40px;
    box-shadow: inset 0px -1px 0px #dde1e6;
  }

  .circleIcon {
    width: 90px;
    height: 90px;
  }

  .companiesLogos {
    padding: 20px;
  }

  .frame7 {
    width: 100%;
    padding: 60px 0px;
  }

  .contentBox {
    max-width: 110px;
    /* Set maximum width instead of fixed width */
  }
}

.carousel {
  position: relative;
  overflow: hidden;
}

.row2 {
  display: flex;
  transition: transform 0.5s ease;
}

/* Additional styling for content boxes and placeholders */

.prevButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.prevButton {
  left: 0;
}

.nextButton {
  right: 0;
}

.whatsappButton {
  position: fixed;
  bottom: 50px;
  right: 50%;
  transform: translate(50%, 50%);
  width: 238.53px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4CAF50;
  /* Green background color */
  color: white;
  /* Text color */
  border: none;
  border-radius: 10px;
  padding: 15px 20px;
  /* Adjust padding as needed */
  font-size: 18px;
  /* Adjust font size as needed */
  cursor: pointer;
  transition: all 0.3s ease;
  /* Smooth transition */
  z-index: 999;
  /* Ensure the button stays on top */
}

.whatsappButtonRelative {
  position: relative;
  bottom: 0;
  right: 0;
  transform: 0;
  width: 238.53px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4CAF50;
  /* Green background color */
  color: white;
  /* Text color */
  border: none;
  border-radius: 10px;
  padding: 15px 20px;
  /* Adjust padding as needed */
  font-size: 18px;
  /* Adjust font size as needed */
  cursor: pointer;
  transition: all 0.3s ease;
  /* Smooth transition */
  z-index: 999;
  /* Ensure the button stays on top */
}

.emptyDiv {
  position: fixed;
  bottom: 50px;
  right: 50%;
  height: 51px;
  transform: translate(50%, 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  padding: 15px 20px;
  /* Adjust padding as needed */
  font-size: 18px;
  /* Adjust font size as needed */
  cursor: pointer;
  transition: all 0.3s ease;
  /* Smooth transition */
  z-index: 999;
  /* Ensure the button stays on top */
}

.whatsappButton:hover {
  background-color: #45a049;
  /* Darker green on hover */
}

.whatsappIcon {
  display: inline-block;
  width: 20px;
  /* Adjust icon size as needed */
  height: 20px;
  margin-right: 10px;
  /* Adjust spacing between icon and text as needed */
  background: url('../../../public/assets/Whatsapp-icon.png') no-repeat center center;
  background-size: cover;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.mainDiv {
  display: flex;
  gap: 60px;
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
  max-height: 100%;
  flex-direction: column;
  align-items: center;
}