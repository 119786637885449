.root {
  position: relative;
  width: 24px;
  height: 24px;
  align-items: flex-start;
  overflow: hidden;
}
.vector {
  position: absolute;
  left: 8.3333%;
  right: 8.3333%;
  top: 16.7291%;
  bottom: 16.6041%;
  overflow: visible;
}
.icon {
  width: 100%;
  height: 100%;
}
