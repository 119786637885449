.panel {
    width: 20%;
    height: 565px;
    flex-direction: column;
    place-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .image {
    width: 273px;
    height: 450px;
    border-radius: 30px;
  }