.root {
  position: relative;
  width: 24px;
  height: 24px;
  align-items: flex-start;
  overflow: hidden;
}
.vector {
  position: absolute;
  left: 8.3333%;
  right: 8.5125%;
  top: 20.9%;
  bottom: 20.9%;
  overflow: visible;
}
.icon {
  width: 100%;
  height: 100%;
}
