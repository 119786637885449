.menuItem {
  color: #fff;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.menuItem2 {
  color: #fff;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.menuItem3 {
  color: #fff;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.menuItem4 {
  color: #fff;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.menuItem5 {
  color: #fff;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.icon {
  width: 100%;
  height: 100%;
}
.icon2 {
  width: 100%;
  height: 100%;
}
.icon3 {
  width: 100%;
  height: 100%;
}
.icon4 {
  width: 100%;
  height: 100%;
}
.icon5 {
  width: 100%;
  height: 100%;
}
.icon6 {
  width: 100%;
  height: 100%;
}
.icon7 {
  width: 100%;
  height: 100%;
}
.root {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 48px;
  padding: 48px 10vw; /* Adjusted padding for responsiveness */
  background-color: #161e21;
}

@media screen and (max-width: 768px) {
  .root {
    padding: 48px 5vw; /* Adjusted padding for smaller screens */
  }
}


.electrify2023AllRightsReserved {
  color: #fff;
  font-size: 14px;
  line-height: 140%;
  font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  flex: 1;
  flex-direction: column;
}

.socialIcons {
  height: min-content;
  flex: 1;
  place-content: flex-end;
  align-items: center;
  gap: 16px;
}

.footer {
  display: flex;
  flex-direction: row;
}
.nav {

}

@media screen and (max-width: 976px) {
  .root {
    padding: 48px 3vw; /* Adjusted padding for even smaller screens */
  }
  .footer {
    display: flex;
    flex-direction: column-reverse;
  }
}