.menuContainer {
    position: absolute;
    top: 100%;
    /* Position it below the header */
    right: 0;
    width: 100%;
    height: min-content;
    background-color: #333;
    padding: 10px 20px;
    z-index: 1000;
    display: flex;
    justify-content: right;
}

.menu {
    list-style-type: none;
    padding: 0;
}

.menuItem {
    display: block;
    padding: 10px 0;
    color: #fff;
    /* Adjust text color as needed */
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.menuItem:hover {
    background-color: #555;
    /* Adjust hover background color as needed */
}

.header {
    position: relative;
    width: 100%;
    height: min-content;
    place-content: space-between;
    align-items: center;
    padding: 16px 80px;
    background-color: #fff;
    box-shadow: inset 0px -2px 0px #161d20;
}

.yosilogoContainer {
    width: 171px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.yosilogo1 {
    width: 100%;
    height: 100%;
    background-image:
        /* webpackIgnore: true */
        url('../../assets/yosilogo1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.menuContainer2 {
    width: min-content;
    height: min-content;
    display: flex;
    align-items: center;
    justify-content: right;
}

.menu2 {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
}

.menuItem2 {
    display: block;
    padding: 10px 0;
    color: rgb(0, 0, 0);
    /* Adjust text color as needed */
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.menuItem2:hover {
    color: #00a8a8;
}

.menuItem:hover {
    color: #00a8a8;
}

@media screen and (max-width: 576px) {
    .yosilogo1 {
        width: 80%;
        height: 80%;
    }
    .header {
        padding: 16px 30px;
    }
}