.whatwedo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: min-content;
    align-items: center;
    gap: 80px 80px;
}


.whatwedo .placeholderpictureinstance {
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 150px;
    border-color: #33e8e8;
    background-image: url(../../../../public/assets/picture.png);
}

.whatwedo .sectionText {
    display: flex;
    flex-direction: column;
    width: 552px;
    align-items: center;
    justify-content: center;
    gap: 16px;
    position: relative;
}

.whatwedo .secondaryheadline {
    position: relative;
    font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
    font-weight: 700;
    color: var(--bg-dark-grey);
    font-size: 36px;
    text-align: center;
    letter-spacing: 0;
    line-height: 52.8px;
    direction: rtl;
}

.whatwedo .paragraph {
    position: relative;
    align-self: stretch;
    font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
    font-weight: 400;
    color: var(--bg-dark-grey);
    font-size: 22px;
    text-align: center;
    letter-spacing: 0;
    line-height: 33.6px;
    direction: rtl;
}

@media only screen and (max-width: 1024px) {

    .whatwedo {
        flex-direction: column;
        gap: 20px;
        width: 90vw;
    }
    .whatwedo .placeholderpictureinstance {
        width: 250px;
        height: 250px;
        border-radius: 125px;
    }
    .whatwedo .secondaryheadline {
        font-size: 28px;
    }
    
    .whatwedo .paragraph {
        font-size: 20px;
        width: 100%;
    }
    .whatwedo .sectionText {
        width: 100%;
    }
}
