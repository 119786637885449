.root {
  position: relative;
  width: 24px;
  height: 24px;
  align-items: flex-start;
  overflow: hidden;
}
.vector {
  position: absolute;
  left: 8.3333%;
  right: 8.4792%;
  top: 8.3333%;
  bottom: 8.4792%;
  overflow: visible;
}
.icon {
  width: 100%;
  height: 100%;
}
.vector2 {
  position: absolute;
  left: 28.4167%;
  right: 28.5542%;
  top: 28.4125%;
  bottom: 28.5583%;
  overflow: visible;
}
.icon2 {
  width: 100%;
  height: 100%;
}
.vector3 {
  position: absolute;
  left: 66.3292%;
  right: 23.3625%;
  top: 23.4208%;
  bottom: 66.2708%;
  overflow: visible;
}
.icon3 {
  width: 100%;
  height: 100%;
}
