.root {
  position: relative;
  width: 24px;
  height: 24px;
  align-items: flex-start;
  overflow: hidden;
}
.vector {
  position: absolute;
  left: 29.3%;
  right: 31.8875%;
  top: 8.3333%;
  bottom: 8.5042%;
  overflow: visible;
}
.icon {
  width: 100%;
  height: 100%;
}
