.frame {
    display: flex;
    flex-direction: column;    
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100vw;
}

.frame .divWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 15px;
}

.frame .textWrapper {
    color: #000000;
    direction: rtl;
    font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 46.2px;
    position: relative;
    width: fit-content;
}

.frame .div {
    align-items: center;
    align-self: stretch;
    display: flex;
    height: 60vh;
    justify-content: space-between;
    padding: 0px 150px;
    position: relative;
    width: 100%;
}
.frame .divReveresed {
    align-items: center;
    align-self: stretch;
    flex-direction: row-reverse;
    display: flex;
    height: 60vh;
    justify-content: space-between;
    padding: 0px 150px;
    position: relative;
    width: 100%;
}

.frame .placeholderPicture {
    align-self: stretch;
    width: 50%;
    height: 100%;
    object-fit: cover;
    position: relative;
}

.frame .div2 {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 0px 30px;
    position: relative;
}


.frame .textWrapper2 {
    align-self: stretch;
    color: #000000;
    direction: rtl;
    font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 32px;
    position: relative;
}

.frame .p {
    align-self: stretch;
    color: #000000;
    direction: rtl;
    font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
    position: relative;
}

.frame .img {
    align-self: stretch;
    flex: 1;
    flex-grow: 1;
    position: relative;
}

@media only screen and (max-width: 1024px) {

    .frame {
        gap: 30px;
    }
    .frame .div {
        flex-direction: column;
        padding: 0px;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    .frame .divReveresed {
        flex-direction: column;
        padding: 0px;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    .frame .placeholderPicture {
        width: 100%;
        height: 70%;
    }
    
    .frame .div2 {
        width: 100%;
    }
    
    .frame .textWrapper {
        font-size: 28px;
    }
    .frame .textWrapper2 {
        font-size: 28px;
    }
}
