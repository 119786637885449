.root {
  width: 456px;
  height: 256px;
  place-content: center;
  background-color: #dde1e6;
  background-image:/* webpackIgnore: true */ url('assets/placeholderPicture.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
