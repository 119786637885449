.frame {
    display: flex;
    height: min-content;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 60px 80px;
    position: relative;
}

.frame .div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px 0px;
    position: relative;
    flex: 1;
    align-self: stretch;
    flex-grow: 1;
}

.frame .img {
    position: relative;
    width: 100px;
    height: 100px;
}

.frame .textWrapper {
    position: relative;
    width: fit-content;
    font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
    font-weight: 700;
    color: #000000;
    font-size: 28px;
    text-align: center;
    letter-spacing: 0;
    line-height: 39.6px;
    white-space: nowrap;
    direction: rtl;
}

.frame .p {
    position: relative;
    width: 307px;
    font-family: Anybody, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
    font-weight: 600;
    color: #000000;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0;
    line-height: 22px;
    direction: rtl;
}


@media only screen and (max-width: 1200px) {
    .frame .img {
        width: 80px;
        height: 80px;
    }
    .frame {
        flex-direction: column;
    }
}
