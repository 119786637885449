.root {
  width: 1280px;
  height: min-content;
  flex-direction: column;
  gap: 48px;
}
.top {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  gap: 8px;
}
.caption {
  color: #001d6c;
  font-size: 20px;
  line-height: 100%;
  font-weight: bold;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.mainHeadline {
  color: #21272a;
  font-size: 54px;
  line-height: 110%;
  font-weight: bold;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.secondaryHeadline {
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.textBlock {
  color: #21272a;
  font-size: 42px;
  line-height: 110%;
  font-weight: bold;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}
.textBlock2 {
  margin-top: 5px;
  color: #21272a;
  font-size: 42px;
  line-height: 110%;
  font-weight: bold;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}
.paragraph {
  color: #21272a;
  font-size: 18px;
  line-height: 140%;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
