.iconJamIconsOutlineLogosPlus.iconJamIconsOutlineLogosPlus {
  z-index: 0;
}
.icon {
  width: 100%;
  height: 100%;
}
.root {
  width: min-content;
  height: 56px;
  place-content: center;
  align-items: center;
  padding: 16px;
  outline: solid 2px #0f62fe;
  outline-offset: -2px;
  background-color: #0f62fe;
}
.textContainer {
  z-index: 0;
  width: min-content;
  height: min-content;
  padding: 0 16px;
}
.buttonText {
  color: #fff;
  font-size: 20px;
  line-height: 100%;
  font-weight: 500;
  font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 0.5px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
