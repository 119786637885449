html,
body,
#root {
  margin: 0;
  padding: 0;
  min-height: 100%;
  display: flex;
  flex-basis: 100%;
  width: 100%;
}

#root {
  position: relative;
}

body button:not(:disabled) {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

body label {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

#NagishLiTrigger {
  right: 0 !important;
  left: 10px;
  bottom: 0 !important;
  top: 150px;
  filter: grayscale(40%);
  width: 200px !important;
}

div#NagishLiWidget {
  left: auto;
  right: 10px;
  transform: translate(0, 120%);
}

div#NagishLiWidget.nagishli-widget-show {
  left: auto;
  right: 10px;
  transform: translate(0, 0);
}